<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Contact</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="editForm">
            <v-text-field
              v-model="formData.address"
              label="Address"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              label="Email"
              :rules="[rules.email]"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.fax"
              label="Fax"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              label="Phone"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.facebook"
              label="Facebook"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.twitter"
              label="Twitter"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.instagram"
              label="Instagram"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.linkedin"
              label="Linkedin"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.youtube"
              label="Youtube"
              dense
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.pinterest"
              label="Pinterest"
              dense
              outlined
            ></v-text-field>
          </form>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      address: null,
      email: null,
      fax: null,
      phone: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,
      youtube: null,
      pinterest: null,
    },
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
  }),
  mounted() {
    this.resetEditForm();
  },
  methods: {
    convertToFormData() {
      let data = new FormData();
      data.append("contact_id", this.$store.getters.getItemForAction.id);
      for (var key in this.formData) {
        if (this.formData[key]) data.append(key, this.formData[key]);
      }
      return data;
    },
    submitEditForm() {
      this.pageLoader(true);
      const self = this;
      const data = {
        contact_id: this.$store.getters.getItemForAction.id,
      };
      ApiService.post("/stores/portal/contacts/edit", data)
        .then((res) => {
          const data = self.convertToFormData();
          if (res) data.append("store_id", res.data.contact.store_id);

          ApiService.post("/stores/portal/contacts/update", data)
            .then(() => {
              self.pageLoader(false);
              Swal.fire({
                title: "Updated",
                text: `The contact data has been updated`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetEditForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      if (this.$store.getters.getItemForAction) {
        this.formData = {
          address: this.$store.getters.getItemForAction.address,
          email: this.$store.getters.getItemForAction.email,
          fax: this.$store.getters.getItemForAction.fax,
          phone: this.$store.getters.getItemForAction.phone,
          facebook: this.$store.getters.getItemForAction.social_link.facebook,
          twitter: this.$store.getters.getItemForAction.social_link.twitter,
          instagram: this.$store.getters.getItemForAction.social_link.instagram,
          linkedin: this.$store.getters.getItemForAction.social_link.linkedin,
          youtube: this.$store.getters.getItemForAction.social_link.youtube,
          pinterest: this.$store.getters.getItemForAction.social_link.pinterest,
        };
      }
    },
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetEditForm();
    },
  },
};
</script>
